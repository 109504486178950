import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../Assests/Copy of CWATY Gold.png';
import { HashLink as ScrollLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';

export const Navigation = () => {
  const [expanded, setExpanded] = useState(false);

  const closeMobileMenu = () => {
    setExpanded(false);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Use smooth scrolling
    });
  };

  const scrollToElement = (elementId, offset = 0) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  
      // Adjust the scroll position by subtracting the offset
      window.scrollBy(0, -offset);
    }
  };

  // Add a listener to reset the scroll when navigating to a new page
  useEffect(() => {
    closeMobileMenu();
    scrollToTop();
  }, []);

  window.addEventListener('scroll', () => {
    const navbar = document.querySelector('.nav-bar');
    if (navbar) {
      if (window.scrollY > 0) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    }
  });

  return (
    <div>
      <div className='mt-3'>
        <Navbar expand="lg" className="nav-bar" expanded={expanded} onToggle={() => setExpanded(!expanded)}  sticky="top">
          <Navbar.Brand href="/">
            <img src={logo} alt='' className='logo' />
          </Navbar.Brand>
          <Navbar.Toggle style={{ border:'none' }} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="">
            <Nav className="nav-item ms-auto">
              <NavLink
                as={Link}
                exact
                to="/"
                className="link-div nav-link"
                activeClassName="active"
                onClick={() => {
                  closeMobileMenu();
                  scrollToTop(); // Scroll to top when clicking the link
                }}
              >
                Home
              </NavLink>

              <Nav.Link
                as={ScrollLink}
                className='nav-link link-div'
                to="/#Aboutus"
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => {
                  closeMobileMenu();
                  // scrollToElement('/aboutus', 100); 
                  // scrollToElement('#aboutus', 100); 
                }}
              >
                About
              </Nav.Link>

              <NavLink
                as={Link}
                exact
                to="/ooha"
                className="link-div nav-link ooha-link"
                activeClassName="active"
                onClick={() => {
                  closeMobileMenu();
                  scrollToTop(); // Scroll to top when clicking the link
                }}
              >
                OOHA
              </NavLink>

              <NavLink
                as={ScrollLink}
                exact
                to="/contact-us"
                className="link-div nav-link"
                activeClassName="active"
                onClick={() => {
                  closeMobileMenu();
                  scrollToTop(); // Scroll to top when clicking the link
                }}
              >
                Contact Us
              </NavLink>

              <Nav.Link
                as={ScrollLink}
                className='nav-link link-div'
                to="/#donate"
                smooth
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                Donate
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className='text-center'>
        <div className='div2'>
          <p>Empowering compassion: Join us in making a positive impact on lives through your generous support.</p>
        </div>
      </div>
    </div>
  );
};

