import logo from './logo.svg';
import './App.css';
import './Contact.css'
import './Ooha.css'
import Home from './Home';
import ContactUS from './ContactUS';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import  Navigation  from './Common/Navigation';
// import Footer from './Common/Footer';
import { Navigation } from './Common/Navigation';
import Footer from './Common/Footer';
import OOHA from './OOHA';
import Donate from './Donate';

function App() {
  return (
    <BrowserRouter basename="">
      <Navigation />
      <Routes>
        {/* <Route path="/" element={<Navigation/>}/> */}
        <Route path='/' element={<Home />} />
        <Route path='/footer' element={<Footer />} />
        <Route path='/contact-us' element={<ContactUS />} />
        <Route path='/ooha' element={<OOHA />} />
        {/* <Route path='/donate' element={<Donate/>}/> */}
      </Routes>

      {/* Your other components and routes go here */}
      {/* <ContactUS/> */}

    </BrowserRouter>
  );
}

export default App;
