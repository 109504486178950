import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { AiOutlineClose, AiOutlineHeart } from "react-icons/ai";
import { CiMedicalCross } from "react-icons/ci";
import { FaBiohazard } from "react-icons/fa6";
// import don1 from './Assests/priya.jpg'
import don1 from "./Assests/img1.jpg";
import don2 from "./Assests/Rectangle 41.png";
// import don3 from "./Assests/s16.jpg";
import don3 from './Assests/don4.jpg'
import don4 from "./Assests/Rectangle 36.png";
// import don4 from './Assests/amrita-jha-cwaty-raveena-tandon.jpg'
import med1 from "./Assests/Group 59.png";
import med3 from "./Assests/Group 59-1.png";
import med2 from "./Assests/Medical Cross.png";
import Footer from "./Common/Footer";
import left_arrow from './Assests/Vector-right.png'
import right_arrow from './Assests/Vector-left.png'
import gal_1 from  "./Assests/gal-img-1.png";
import gal_2 from  "./Assests/gal-img-2.png";
import gal_3 from  "./Assests/gal-img-3.png";
import gal_4 from  "./Assests/gal-img-4.png";
import gal_5 from  "./Assests/gal-img-5.png";
import gal_6 from "./Assests/Rajeev1.png"
import gal_7 from "./Assests/s15.jpg"
import gal_8 from "./Assests/Snapinsta 1.png"
import gal_9 from "./Assests/img1.jpg"
import gal_10 from "./Assests/s2.jpg"
import gal_11 from "./Assests/s3.jpg"
import gal_12 from "./Assests/s20.jpg"
import gal_13 from "./Assests/s18.jpg"
import gal_14 from "./Assests/s6.jpg"
import gal_15 from "./Assests/s7.jpg"
import gal_16 from "./Assests/s8.jpg"
import gal_17 from "./Assests/s9.jpg"
import gal_18 from "./Assests/s6.jpg"
import gal_19 from "./Assests/s11.jpg"
import gal_20 from "./Assests/s12.jpg"
// import "./ooha.css";
const OOHA = () => {
  const images=[
    gal_1,
    gal_2,
    gal_3,
    gal_4,
    gal_5,
    gal_6,
    gal_7,
    gal_8,
    gal_9,
    gal_10,
    gal_11,
    gal_12,
    gal_13,
    gal_14,
    gal_15,
    gal_16,
    gal_17,
    gal_18,
    gal_19,
    gal_20,
    don1,
    don3,
    don4,
    ]

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  const openGallery = () => {
    setIsGalleryOpen(true);
    setBodyScrollEnabled(false);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
    setBodyScrollEnabled(true);
  };

  const showImage = (index) => {
    if (index < 0) {
      index = images.length - 1; // Wrap around to the last image
    } else if (index >= images.length) {
      index = 0; // Wrap around to the first image
    }
  
    setCurrentIndex(index);
    setSelectedImageIndex(index);
  
    // Calculate the scroll position to center the selected image
     if (sliderImgRef.current) {
      const selectedImage = sliderImgRef.current.querySelector(`.preview-div:nth-child(${index + 1})`);
    selectedImage.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    
  };

  useEffect(() => {
    // Disable or enable body scrolling based on the bodyScrollEnabled state
    if (bodyScrollEnabled) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [bodyScrollEnabled]);
  
  
  
  // const showImage1 = (index) => {
  //   setCurrentIndex(index);
  //   setSelectedImageIndex(index);
  //   if(index==0){
  //     setCurrentIndex(5);
  //   }
  // };
console.log(currentIndex);
  const galleryStyle = {
    display: isGalleryOpen ? 'block' : 'none',
  };


  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const sliderImgRef = useRef(null);


  return (
    <div>
      <div className="div-ooha-1">
        <div className="div-ooha-1-content">
          <p>Be a part of the</p>
          <h1>OOHA Movement</h1>
          {/* <button>Share On Instagram</button> */}
        </div>
      </div>
      <div className="div-90">
        <div className="div-ooha-2">
          <div className="div-ooha-2-head">
            <h2 className="titel_second_page">Why OOHA ?</h2>
            <p>Indi streetys go unattended and starve most of the time.</p>
          </div>
          <div className="d-flex div-ooha-2-card">
            <div className="ooha-card">
              <Card className="card-med">
                <Card.Body>
                  <Card.Title className="text-center">
                    <img src={med1} className="card-icon" />
                  </Card.Title>
                  <Card.Text className="card-txt-med">
                    We may love  them all, but do  not or cannot bring them home
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="ooha-card">
              <Card className="card-med">
                <Card.Body>
                  <Card.Title className="text-center">
                    <img src={med2} className="card-icon" />
                  </Card.Title>
                  <Card.Text className="card-txt-med">
                    No medical facilities  reach them. They die  ill
                    and way before  their time.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="ooha-card">
              <Card className="card-med">
                <Card.Body>
                  <Card.Title className="text-center">
                    <img src={med3} className="card-icon text-center" />
                  </Card.Title>
                  <Card.Text className="card-txt-med">
                    It's got tougher for them to survive during  the pandemic</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div className="adoption-div">
          <div>
            <h2>
              Out Of Home Adoption: a way to co-exist with our fellow species
            </h2>
          </div>
          <div>
            <p>
              OOHA or Out Of Home Adoption is a campaign to inspire and urge
              people to help street animals (streetys) in and around their
              neighborhood.Take care of the animal(s) on a regular basis as
              their own pets There are 5 steps to doing the OOHA
            </p>
          </div>
        </div>
        <div className="adoption-img-div m-auto">
          <div className="d-flex adoption-flex m-auto">
            <div className="don4">
              <img src={don4} alt="" />
            </div>
            <div className="btn-img">
              <div className="d-flex img-3-div">
                <div className="don1">
                  <img src={gal_9} alt="" />
                </div>
                <div className="don2-3">
                  <div className="don2">
                    <img src={don2} alt="" />
                  </div>
                  <div className="don3">
                    <img src={don3} alt="" />
                  </div>
                </div>
              </div>
              <div>
                <button onClick={openGallery}>Know More</button>
                
              </div>
            </div>
          </div>
        </div>
        <div className="img-gallery">
      {/* <button onClick={openGallery}>Know More</button> */}
          {isGalleryOpen && (
            <div className="gallery-popup">
              <div className="gallery-content" >
                <button className="close-button" onClick={closeGallery}>
                  <AiOutlineClose />
                </button>
                <div className="blur-background"></div>
                  <div className="image-gallery">
                    <button className="arrow left-arrow" onClick={() => showImage(currentIndex-1)}>
                      <img src={left_arrow} alt="" width={'50px'} />
                    </button>
                    <img src={images[currentIndex]} alt="Image" className="gallery-image" />
                    <button className="arrow right-arrow" onClick={() => showImage(currentIndex+1)}>
                      <img src={right_arrow} alt="" width={'50px'} />
                    </button>
                  </div>
                  <div className="slider-img" ref={sliderImgRef} >
                  <div className="preview-img">
                  {images.map((image, index) => (
                    <div key={index} className={`preview-div ${selectedImageIndex === index ? 'selected' : ''}`} onClick={() => showImage(index)}>
                      <img src={image} alt="" />
                    </div>
                  ))}
                  </div>
                  </div>
              </div>
            </div> 
          )}
        </div>
      </div>
      
      
      
      <div className="steps-div">
        <h1 className="text-center"> 5 Steps of OOHA</h1>
        <div className="d-flex step-flex">
          <div className="step-20">
            <Card className="card-step">
              <Card.Body className="new_ooha">
                <Card.Title className="text-center step-title ">
                  Name & Collar
                </Card.Title>
                <Card.Text className="step-txt">Your Streety</Card.Text>
                <h1 className="ooha-step-1">1</h1>
              </Card.Body>
            </Card>
          </div>
          <div className="step-20">
            <Card className="card-step">
              <Card.Body className="new_ooha">
                <Card.Title className="text-center step-title">
                  Water
                </Card.Title>
                <Card.Text className="step-txt">
                  Ensure That There Is <br />
                  Water 24x7
                </Card.Text>
                <h1 className="ooha-step-1">2</h1>
              </Card.Body>
            </Card>
          </div>
          <div className="step-20">
            <Card className="card-step">
              <Card.Body className="new_ooha">
                <Card.Title className="text-center step-title">Feed</Card.Title>
                <Card.Text className="step-txt">
                  your streety twice a day
                </Card.Text>
                <h1 className="ooha-step-1">3</h1>
              </Card.Body>
            </Card>
          </div>
          <div className="step-20">
            <Card className="card-step">
              <Card.Body className="new_ooha">
                <Card.Title className="text-center step-title">Time</Card.Title>
                <Card.Text className="step-txt">
                  Spend ten minutes a<br />
                  day with your streety
                </Card.Text>
                <h1 className="ooha-step-1">4</h1>
              </Card.Body>
            </Card>
          </div>
          <div className="step-20">
            <Card className="card-step">
              <Card.Body className="new_ooha">
                <Card.Title className="text-center step-title">
                  Medical
                </Card.Title>
                <Card.Text className="step-txt">
                  supplies <br />
                  for your streety
                </Card.Text>
                <h1 className="ooha-step-1">5</h1>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <div className="view-btn">
        {/* <a target="_blank" href="https://www.instagram.com/cwaty.official/">
          <button>View on instagram</button>
        </a> */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default OOHA;
