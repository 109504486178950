import React from "react";
import logo from "../Assests/Copy of CWATY Gold.png";
import love from "../Assests/Vector.png";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const nav = useNavigate();
  const navigate = () => {
    nav("/ooha");
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="footer text-light">
        <div className="footer-flex">
          <div className="footer-1">
            <img src={logo} alt="" className="footer-logo" />
            <p className="footer_sub_details">
              Welcome to CWATY, a compassionate force for change dedicated to
              charity's
              mission. Since our inception, we have been unwavering in
              our commitment
              to our specific goals.
            </p>
          </div>
          <div className="footer-cmpny">
            <h5>Company</h5>
            <p className="p1">
              505, Embassy, Lokhandwala Circle, <br />
              Shastri Nagar, Andheri West,
              <br />
              Mumbai, Maharashtra 400053
            </p>
          </div>
          {/* <div className='footer-conent1'> 
                    <h5>Collaborate</h5>
                        <h6>Cwaty Connect</h6>
                        <h6>Advanced Search</h6>
                        <h6>Browse</h6>
                </div>       */}
          <div className="footer-conent2">
            <h5>Quick Links</h5>

              <a className="oha_fot" onClick={navigate}><h6>OOHA</h6></a>
            {/* <h6>CEP 2.0</h6> */}
          </div>
          <div className="contact-us">
            <h5> Contact us</h5>

            <p>
              Email:
              <br />
              <a  className="footer_mail_phone" href="mailto:bhuvna@cwaty.org">khia@cwaty.org</a>
              
            </p>

            <p>
              Phone:
              <br />
              <a  className="footer_mail_phone" href="tel:+91 9867335535">+91 98673 35535</a>
              
            </p>
          </div>
        </div>
        <div className="text-center footer-bot mt-4">
          <div>
            Copyright © 2023{" "}
            <a href="#" className="ref">
              CWATY
            </a>{" "}
            | All Rights Reserved
          </div>
          <div className="last">
            Created with <img src={love} alt="" width={"15px"} /> by &nbsp; <a href="https://binarygeckos.com/" target="_blank" className="oha_fot"> Binary
            Geckos</a>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
