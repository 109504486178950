import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Container, Nav, Navbar } from "react-bootstrap";
import logo from "./Assests/Copy of CWATY Gold.png";
import p1 from "./Assests/logo4-360x213 1.png";
import p2 from "./Assests/milaap 1.png";
import p3 from "./Assests/logo5-360x213 1.png";
import p4 from "./Assests/ketto 1.png";
import p5 from "./Assests/logo2-360x213 1.png";
import p6 from "./Assests/p6.png";
import p7 from "./Assests/dk 1.png";
import p8 from "./Assests/payment 1.png";
// import p9 from "./Assests/logo3-360x213 1.png";
import p9 from "./Assests/cep.png";
import p10 from "./Assests/indian 1.png";
import { BiRightArrowAlt, BiRightArrowCircle } from "react-icons/bi";
import { TbBrandLinkedin } from "react-icons/tb";
import { Rating } from "@mui/material";
import rect1 from "./Assests/cwatynew.png";
import rect2 from "./Assests/Rectangle 26.png";
import rect3 from "./Assests/Rectangle 21.png";
import rect4 from "./Assests/Rectangle 25.png";
import rect5 from "./Assests/Rectangle 20.png";
import rect6 from "./Assests/Mask group.png";
import donate from "./Assests/homebanner2.png";
import testi1 from "./Assests/testi1.png";
import testi2 from "./Assests/testi2.png";
import testi3 from "./Assests/testi3.png";
import instagram from "./Assests/image 2.png";
import love from "./Assests/Vector.png";
import arrow1 from "./Assests/Arrow1.png";
import { PiLinkedinLogoBold } from "react-icons/pi";
import img1 from "./Assests/Rectangle 6.png";
import img2 from "./Assests/Rectangle 4.png";
import img3 from "./Assests/gal-img-4.png";
import img4 from "./Assests/gal-img-2.png";
import img5 from "./Assests/gal-img-5.png";
import img6 from "./Assests/gal-img-3.png";
import img7 from "./Assests/gal-img-1.png";
import { motion, useAnimation } from "framer-motion";
import { InstagramEmbed } from "react-social-media-embed";
import { useNavigate } from "react-router-dom";
import Footer from "./Common/Footer";
import linkedlogo from "./Assests/Group 39.png";
import { useHistory } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import "./home.css";
// import { Link, animateScroll as scroll } from 'react-scroll';

const Home = () => {
  const images = [
    {
      img: img3,
    },
    {
      img: img4,
    },
    {
      img: img5,
    },
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img6,
    },
    {
      img: img7,
    },
    {
      img: img3,
    },
    {
      img: img4,
    },
    {
      img: img5,
    },
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img6,
    },
    {
      img: img7,
    },
    {
      img: img3,
    },
    {
      img: img4,
    },
    {
      img: img5,
    },
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img6,
    },
    {
      img: img7,
    },
  ];
  const review = [
    {
      img: testi1,
      name: "Jacob Archer",
      position: "Haacht",
      review:
        "I have had the incredible privilege of being involved with Cwaty for the past 4 years, and I can t help but be amazed by the immense impact this organization has on our community. Their dedication is truly commendable.",
    },
    {
      img: testi2,
      name: "Jan Adriaensens",
      position: "Haacht",
      review:
        "I am truly honored to share my experience with Cwaty, an organization that has touched my heart and changed lives in ways that words can hardly express. From the very moment I became involved with this incredible charity, I knew I had found a community of compassionate individuals dedicated to making a positive impact.",
    },
    {
      img: testi3,
      name: "Justin Abbott",
      position: "Haacht",
      review:
        "ve been fortunate to connect with Cwaty& witness their incredible work firsthand.This organization is a true testament to the power of compassion and dedication.",
    },
    {
      img: testi1,
      name: "Jacob Archer",
      position: "Haacht",
      review:
        "I have had the incredible privilege of being involved with Cwaty for the past 4 years, and I can t help but be amazed by the immense impact this organization has on our community. Their dedication is truly commendable.",
    },
    {
      img: testi2,
      name: "Jan Adriaensens",
      position: "Haacht",
      review:
        "I am truly honored to share my experience with Cwaty, an organization that has touched my heart and changed lives in ways that words can hardly express. From the very moment I became involved with this incredible charity, I knew I had found a community of compassionate individuals dedicated to making a positive impact.",
    },
    {
      img: testi3,
      name: "Justin Abbott",
      position: "Haacht",
      review:
        "ve been fortunate to connect with Cwaty& witness their incredible work firsthand.This organization is a true testament to the power of compassion and dedication.",
    },
    {
      img: testi1,
      name: "Jacob Archer",
      position: "Haacht",
      review:
        "I have had the incredible privilege of being involved with Cwaty for the past 4 years, and I can t help but be amazed by the immense impact this organization has on our community. Their dedication is truly commendable.",
    },
    {
      img: testi2,
      name: "Jan Adriaensens",
      position: "Haacht",
      review:
        "I am truly honored to share my experience with Cwaty, an organization that has touched my heart and changed lives in ways that words can hardly express. From the very moment I became involved with this incredible charity, I knew I had found a community of compassionate individuals dedicated to making a positive impact.",
    },
    {
      img: testi1,
      name: "Jacob Archer",
      position: "Haacht",
      review:
        "I have had the incredible privilege of being involved with Cwaty for the past 4 years, and I can t help but be amazed by the immense impact this organization has on our community. Their dedication is truly commendable.",
    },
    {
      img: testi2,
      name: "Jan Adriaensens",
      position: "Haacht",
      review:
        "I am truly honored to share my experience with Cwaty, an organization that has touched my heart and changed lives in ways that words can hardly express. From the very moment I became involved with this incredible charity, I knew I had found a community of compassionate individuals dedicated to making a positive impact.",
    },
    {
      img: testi3,
      name: "Justin Abbott",
      position: "Haacht",
      review:
        "ve been fortunate to connect with Cwaty& witness their incredible work firsthand.This organization is a true testament to the power of compassion and dedication.",
    },
    {
      img: testi1,
      name: "Jacob Archer",
      position: "Haacht",
      review:
        "I have had the incredible privilege of being involved with Cwaty for the past 4 years, and I can t help but be amazed by the immense impact this organization has on our community. Their dedication is truly commendable.",
    },
    {
      img: testi2,
      name: "Jan Adriaensens",
      position: "Haacht",
      review:
        "I am truly honored to share my experience with Cwaty, an organization that has touched my heart and changed lives in ways that words can hardly express. From the very moment I became involved with this incredible charity, I knew I had found a community of compassionate individuals dedicated to making a positive impact.",
    },
    {
      img: testi3,
      name: "Justin Abbott",
      position: "Haacht",
      review:
        "ve been fortunate to connect with Cwaty& witness their incredible work firsthand.This organization is a true testament to the power of compassion and dedication.",
    },
    {
      img: testi1,
      name: "Jacob Archer",
      position: "Haacht",
      review:
        "I have had the incredible privilege of being involved with Cwaty for the past 4 years, and I can t help but be amazed by the immense impact this organization has on our community. Their dedication is truly commendable.",
    },
    {
      img: testi2,
      name: "Jan Adriaensens",
      position: "Haacht",
      review:
        "I am truly honored to share my experience with Cwaty, an organization that has touched my heart and changed lives in ways that words can hardly express. From the very moment I became involved with this incredible charity, I knew I had found a community of compassionate individuals dedicated to making a positive impact.",
    },
  ];
  const team = [
    {
      logo: rect1,
      name: "Cwaty",
      position: "FOUNDER",
      linkedin: "https://www.linkedin.com/company/cwaty/about/",
    },
    {
      logo: rect2,
      name: "Jagrat Desai",
      position: "CO-FOUNDER",
      linkedin: "https://www.linkedin.com/in/jagrat-desai-8b64125a/",
    },
    {
      logo: rect3,
      name: "Khia Wadhawan",
      position: "CO-FOUNDER",
      linkedin: "https://www.linkedin.com/in/khia-wadhawan-93836732/",
    },
    // {
    //   logo: rect4,
    //   name: "Somu Desai",
    //   position: "CO-FOUNDER",
    //   linkedin: "https://www.linkedin.com/in/somu-desai-99ba2a91/",
    // },
    // {
    //   logo: rect5,
    //   name: "Gokul Nair",
    //   position: "CO-FOUNDER",
    //   linkedin: "https://www.linkedin.com/in/thegokulnair/",
    // },
    // {
    //   logo: rect6,
    //   name: "Ravi Nimavat",
    //   position: "CO-FOUNDER",
    //   linkedin: "https://www.linkedin.com/in/ravi-nimavat-344a98b5/",
    // },
  ];

  const nav = useNavigate();
  const navigate = () => {
    nav("/ooha");
    window.scrollTo(0, 0);
  };

  const [width, setwidth] = useState(0);
  const [width2, setwidth2] = useState(0);
  // useEffect(() => {
  //   setwidth(reviewRef.current.scrollWidth - reviewRef.current.offsetWidth);
  //   setwidth2(galleryRef.current.scrollWidth - galleryRef.current.offsetWidth);
  // }, []);
  const galleryRef = useRef(null);
  // const reviewRef = useRef(null);
  // const scrollInterval = 3000;
  return (
    <div className="main">
      <div className="div3">
        <div className="div3-content">
          <h1>
            Together For Good:
            <br />
            Uniting Hearts, Changing Lives.
          </h1>

          <p>
            At Cwaty, we believe in the power of compassion & <br />
            collective action to bring positive change to the world.
          </p>
          <Link
            className="form-link-1"
            activeClass="active1"
            to="aboutus"
            spy={true}
            smooth={true}
            offset={-120} // Adjust the offset as needed
            duration={500}
          >
            <button>Learn More</button>
          </Link>
        </div>
        <div className="
        div3-img">
          <img src={logo} />
        </div>
      </div>
      <div id="Aboutus"></div>
      <div className="div7 text-center mt-5" id="aboutus">
        <h3>ABOUT CWATY</h3>
        <h1>Not your regular Canine;</h1>
        <p className="about_del_new">
          I am an entertainer on a mission to bridge the gap between humans and
          animals and have some pretty amazing stories up my paws to share. When
          people do good for animals I share their positive stories on my social
          media and give them a pawfi. Together we can bring about a new era of
          welfare for animals!
        </p>
      </div>
      {/* <div className='scrolling-wrapper'>
        {images.map((i)=>{
            return(
                <div className='text-center div8'>
                    <Card className='card-div' >
                        <Card.Img variant="top" src={i.img} className='card-div' />
                    </Card>
                </div>
            )
        })}
    </div> */}
      <div className="carousel-main">
        <motion.div ref={galleryRef} className="carousel">
          <motion.div
            drag="x"
            dragConstraints={{ right: 0, left: -width2 }}
            className="inner-carousel"
          >
            {images.map((image, index) => {
              return (
                <motion.div className="item" key={index}>
                  <img src={image.img} alt="" />
                </motion.div>
              );
            })}
          </motion.div>
        </motion.div>
      </div>
      <div className="div4">
        <p>
          At Cwaty, our journey towards creating positive change is powered by
          the unwavering support of individuals such as,
        </p>
      </div>
      <div className="arrow-div text-center">
        <img src={arrow1} alt="" width={"230px"} />
      </div>
      <div class="div5 text-center">
        <div class="div5-1">
          <img src={p1} alt="" class="part-img" />
          <img src={p2} alt="" class="part-img" />
          <img src={p3} alt="" class="part-img " />
          <img src={p4} alt="" class="part-img " />
          <img src={p5} alt="" class="part-img" />
          <img src={p6} alt="" class="part-img " />
          <img src={p7} alt="" class="part-img" />
          <img src={p8} alt="" class="part-img" />
          <img src={p9} alt="" class="part-img" />
          <img src={p10} alt="" class="part-img" />
        </div>
      </div>

      <div className="text-center div-discover">
        <p>Discover All Companies On CWATY Connect</p>
      </div>
      <div className="div6 m-auto">
        <div className="text-center rating2">
          <h1>100+</h1>
          <p className="rt_nm">Engage hundreds of your Donners</p>
        </div>
        <div className="text-center rating1">
          <h1>500+</h1>
          <p className="rt_nm">Companies on Cwaty</p>
        </div>
        <div className="text-center rating1">
          <h1>10+</h1>
          <p className="rt_nm">Our Supporters</p>
        </div>
      </div>
      <div className="div9 col-12 col-sm-6 col-md-4 col-lg-3">
        <div className="div9-head">
          <h2>
            Out Of Home Adoption:
            <br />A way To Co-Exist With Our Fellow Species
          </h2>
          <p>
            OOHA or Out Of Home Adoption is a campaign to inspire and urge
            people to help
            <br />
            street animals (streetys) in and around their neighborhood.
          </p>
        </div>
        <div className="d-flex div9-content">
          <div className="">
            <h3>Be A Part Of The OOHA Movement</h3>
          </div>
          <div>
            {/* <Link to='/ooha' > */}
            <button onClick={navigate}>
              Learn More
              <BiRightArrowAlt className="btn-icon" />
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <div className="div10 col-12 col-sm-6 col-md-4 col-lg-3 ">
        {/* <div className="div10-head ">
          <p>Testimonials</p>
        </div>
        <div className="text-center div10-p">
          <h1>Building Trust Through Shared Stories</h1>
          <p className="nm_testimonisals">
            At Cwaty, we are committed to making a difference in the lives of
            those in need. Our efforts are driven by the stories of individuals
            and communities that we ve been fortunate to support. Here's what
            some of our beneficiaries and supporters have to say about their
            experiences with us
          </p>
        </div> */}
        <div className="carousel-review-main">
          {/* <motion.div ref={reviewRef} className="carousel-review">
            <motion.div
              drag="x"
              dragConstraints={{ right: 0, left: -width }}
              className="inner-carousel-review"
            >
              {review.map((i) => {
                return (
                  <motion.div className="item1-review" key={i}>
                    <motion.div className="div-10-card">
                      <Card className="card-div2">
                        <motion.div className="d-flex">
                          <motion.div className="m-4 text-center">
                            <Card.Img
                              variant="top"
                              src={i.img}
                              className="card-img"
                            />
                            <Card.Title className="card-title1">
                              {i.name}
                            </Card.Title>
                            <h7 style={{ color: "white" }}>{i.position}</h7>
                          </motion.div>
                          <motion.div>
                            <Card.Body>
                              <Card.Title>
                                <Rating
                                  className="rating"
                                  name="half-rating-read"
                                  defaultValue={5}
                                  precision={0.5}
                                  max={5}
                                  readOnly
                                />
                              </Card.Title>
                              <Card.Text className="card-txt">
                                {i.review}
                              </Card.Text>
                            </Card.Body>
                          </motion.div>
                        </motion.div>
                      </Card>
                    </motion.div>
                  </motion.div>
                );
              })}
            </motion.div>
          </motion.div> */}
        </div>
      </div>
      {/* <center> */}
      <br />
      {/* </center> */}
      <div className="text-center div-team m-auto">
        <h2 className="meet_h2">Meet our Team</h2>
        <div className="div-team-flex row text-center ">
          {team.map((i) => {
            return (
              <div className="div-team-1">
                <Card className="card-team">
                  <Card.Img
                    variant="top"
                    src={i.logo}
                    className="div-team-img"
                  />
                  <Card.Body>
                    <Card.Title className="card-team-1">{i.name}</Card.Title>
                    <Card.Title className="card-team-2">
                      {i.position}
                    </Card.Title>
                    <Card.Link href={i.linkedin} className="link-btn">
                      <img src={linkedlogo} alt="" className="linked-logo" />
                    </Card.Link>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <a className="donate-secion"></a>
      <div className="donate-div" id="donate">
        <div className="donate-content">
          <h1>Donate to Support</h1>
          <h3>BANK A/C DETAILS</h3>
          <h4>CWATVERSE FOUNDATION,</h4>
          <p>
            {" "}
            Account no: 50200054374461
            <br />
            IFSC : HDFC0000159
          </p>
          <a href="https://rzp.io/l/3cOV388" target="_blank">
            <button>Donate</button>
          </a>
        </div>
        <div className="donate-img-div">
          <img src={donate} className="donate-img" />
        </div>
      </div>
      <div className="connect-insta">
        <a target="_blank" href="https://www.instagram.com/cwaty.official/">
          <button>Connect To Instagram</button>
        </a>
      </div>
      <div className="text-center  social-div">
        <div className="social-div-1">
          <InstagramEmbed
            className="div-insta-img"
            url="https://www.instagram.com/p/COKVJ_aJ2rr/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
          />
        </div>
        <div className="social-div-1">
          <InstagramEmbed
            className="div-insta-img"
            url="https://www.instagram.com/p/COPTuzDJ6iW/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
          />
        </div>
        <div className="social-div-1">
          <InstagramEmbed
            className="div-insta-img"
            url="https://www.instagram.com/p/COpg8Iop5EO/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
          />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
