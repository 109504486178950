import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { BiRightArrowAlt } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import Footer from "./Common/Footer";
import { Link, animateScroll as scroll } from "react-scroll";

import copy from "./Assests/Copy of Paw 5 CWATY 1.png";
import arrow from "./Assests/Arrow.png";
import arrow3 from "./Assests/Arrow3.png";
import success from "./Assests/success-contact-4.png";
import Spinner from 'react-bootstrap/Spinner';

const ContactUS = () => {
  const [validation, setValidation] = useState({});
  const [hasError, setHasError] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const form = e.target;
    const valid = validateForm(form);

    if (valid) {
      setLoading(true); // Start loading
      emailjs
        .sendForm(
          "service_m16bhhw",
          "template_dkhyjlj",
          e.target,
          "To39NQAcQhAwoZeHp"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
            setPopupOpen(true);
            setLoading(false); // Stop loading
            setTimeout(() => {
              setPopupOpen(true);
            },);
          },
          (error) => {
            console.log(error.text);
            setHasError(true);
            setLoading(false);
          }
        );
    }
  };

  const validateForm = (form) => {
    const validationData = {};
    let valid = true;

    if (!form.elements.user_name.value) {
      validationData.user_name = "First Name is required!";
      valid = false;
      setHasError(true);
    } else {
      setHasError(false);
    }

    if (!form.elements.last_name.value) {
      validationData.last_name = "Last Name is required!";
      valid = false;
    }

    if (!form.elements.mobile_number.value) {
      validationData.phone = "Phone is required!";
      valid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (
      !form.elements.email_user.value ||
      !emailPattern.test(form.elements.email_user.value)
    ) {
      validationData.email = "Valid Email is required!";
      valid = false;
    }

    setValidation(validationData);
    return valid;
  };

  const closePopup = () => {
    setPopupOpen(false);
    form.current.reset();
  };

  return (
    <div>
      <div className="div-3-contact m-auto">
        <div className="contact-flex">
          <h1 className="h1_contact">Contact Us</h1>
        </div>
        <div className="contact-flex2">
          <img src={copy} alt="" className="copy-img" />
        </div>
      </div>
      <div className="arrow-1-div">
        <img src={arrow3} alt="" width={"175px"} height={"91px"} />
      </div>
      <div className="d-flex tab_contact div-4" id="contact-form">
        <div className="div-4-content">
          <p>
            Get In Touch With
            <br /> Cwaty
          </p>
        </div>
        <div className="form-div-4">
          <div>
            <form ref={form} onSubmit={sendEmail} className="form-main">
              <input
                type="text"
                name="user_name"
                placeholder="First Name"
                className={`form-input ${hasError ? "form-user" : ""}`}
              />
              {validation.user_name && (
                <span className="validation-error">{validation.user_name}</span>
              )}

              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                className="form-input last-name"
              />
              {validation.last_name && (
                <span className="validation-error">{validation.last_name}</span>
              )}

              <input
                type="number"
                name="mobile_number"
                placeholder="Phone"
                className="form-input3"
                pattern="[0-9]*"
              />
              {validation.phone && (
                <span className="validation-error">{validation.phone}</span>
              )}

              <input
                type="email"
                name="email_user"
                placeholder="Email"
                className="form-input3"
              />
              {validation.email && (
                <span className="validation-error">{validation.email}</span>
              )}

              <textarea
                name="message"
                placeholder="Write Here"
                className="form-input-area"
              />
              <br />

              <button type="submit" className="form-btn">
                SEND
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="popup-container">
        {loading && (
            <div className="popup-loader">
            <Spinner animation="border" className="text-center" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
         )} 
      </div>
      <div className="arrow-contact">
        <img src={arrow} alt="" width={"157px"} height={"91px"} />
      </div>
      <div className="div-5">
        <div className="d-flex flex-div-5">
          <div className="flex-1">
            <p>
              Celebrate the journey of the world’s first virtual animal
              <br /> being & animal welfare influencer
            </p>
            <h1>Become a partner</h1>
            <p>
              If you are an animal welfare organization or an activist that can
              benefit
              <br /> by participating in CEP 2.0. We would love to hear from you
            </p>
          </div>
          <div className="flex-2">
            <Link
              className="form-link-1"
              activeClass="active1"
              to="contact-form"
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed
              duration={500}
            >
              <button>
                Join Cwaty
                <BiRightArrowAlt className="btn-icon" />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="popup-container">
        {isPopupOpen && (
          <div className="popup">
            <div className="popup-content">
              <span className="close-button-pop" onClick={closePopup}>
                &times;
              </span>
              <div>
                <img src={success} alt="" />
              </div>
              <h2>Success</h2>
              <p>Message Send Successfully</p>
              <hr />
              <button onClick={closePopup}>Ok</button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ContactUS;